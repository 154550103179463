import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center md:flex-row px-10" }
const _hoisted_2 = { class: "prefix mt-10" }
const _hoisted_3 = { class: "text-s text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_image = _resolveComponent("n-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_image, {
      class: _normalizeClass([
        _ctx.order ? 'md:order-1' : 'md:order-2',
        'md:w-1/2',
        'order-2',
        'wow animate__animated',
        !_ctx.order ? 'animate__slideInRight' : 'animate__slideInLeft',
      ]),
      src: _ctx.imgurl
    }, null, 8, ["class", "src"]),
    _createElementVNode("div", {
      class: _normalizeClass([
        !_ctx.order ? 'md:order-1' : 'md:order-2',
        'space-y-3',
        'flex',
        'flex-col',
        'justify-center',
        'md:w-1/2',
        'wow animate__animated',
        _ctx.order ? 'animate__slideInRight' : 'animate__slideInLeft',
        !_ctx.order ? 'md:mr-20' : 'md:ml-20',
      ])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.msg), 1)
    ], 2)
  ]))
}