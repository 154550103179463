
import { defineComponent } from "vue";
import JcCard from "./AboutCommponent/JcCard.vue";
import AdvantageCard from "./AboutCommponent/AdvantageCard.vue";
export default defineComponent({
  components: { JcCard, AdvantageCard },
  setup() {
    return {
      bigtitle1: "企业简介",
      indexCard: [
        {
          imgurl: require("../assets/about/a5.png"),
          msg: "晟校科技(北京)有限公司成立于2020年，拥有强大的技术研发能力，位于北京市通州区京杭广场，公司紧紧围绕学生餐企业生产运营中的痛点需求精准研发，形成一整套可落地的“开心虎”产品解决方案，目前平台入驻规模以上学生餐企业百余家，遍布北京、天津、河北、山东、江苏等地，秉承以赋能的方式，平台链接利益共享的理念，加持学生餐企业健康高效发展，努力把“开心虎”打造成为全国最受尊敬的学生餐企业服务平台！",
        },
      ],

      moduleList: [
        {
          imgurl: require("../assets/about/qyhxys1.png"),
          title: "市场",
          msg: "市场足够大，连带教育用品市场规模在亿万级以上产品足够好，为各方减负、增效、增收，政府学校家长都欢迎，学生也欢迎。",
          order: false,
        },
        {
          imgurl: require("../assets/about/qyhxys2.png"),
          title: "产品",
          msg: "“开心虎”一直致力于建设完善全国食品安全城市的整体解决方案，实现全领域食品安全追溯监管与构建生态体系。通过平台化，具备落地简单便捷以及模式快速复制的能力，能够向全国推广使用，在商品安全方面能够为全社会做出贡献。",
          order: true,
        },
        {
          imgurl: require("../assets/about/qyhxys3.png"),
          title: "技术团队",
          msg: "技术团队结构合理力量雄厚，对市场反应快执行力强,长于合作，勇于拼搏。",
          order: false,
        },
      ],
    };
  },
});
